/* import fonts */

@font-face {
	/* font-family: "tt-autonomous";
	src: url("../fonts/TT_Autonomous_Trial_Regular.ttf") format("ttf"); */
	font-family: "Open Sans", sans-serif;
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 18px;
}

body {
	margin: 0;
	--color-text: #f2f7ff;
	--color-accent: 180 220 255;
	/* --color-bg: #040e1c; */
	/* #041f1a; */
	/* --color-bg: oklch(0.22 0.04 176.23 / 1); */
	--color-bg: #040e1c;
	--color-transparent-bg: #00020590;
	--bg-blur: blur(6px);
	--color-border: #7eb4ff80;
	--color-link: #f2f7ff;
	--color-link-hover: #fff;
	--color-circular-inner-bg: #cce0ff;
	color: var(--color-text);
	background-color: var(--color-bg);

	max-width: 100vw;

	/* Use gradiend */
	/* background: rgb(12, 61, 52);
	background: radial-gradient(circle, rgba(12, 61, 52, 1) 0%, rgba(5, 23, 20, 1) 100%); */

	/* font-family: mono45-headline, monospace; */
	font-family: "Open Sans", sans-serif;
	font-family: "Ubuntu", sans-serif;
	font-kerning: none;
	font-feature-settings: "ss01" 1, "ss02" 1, "ss03" 1, "ss04" 1; /* Monospace-Eigenschaft aktivieren */
	font-variant-numeric: tabular-nums;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.lupe {
	opacity: 0 !important;
	position: fixed;
	margin-left: 50svw;
	margin-top: 50svh;
	top: 0;
	left: 0;
	width: 0px;
	height: 0px;
	pointer-events: none;
	z-index: 1000;
	border-radius: 50%;
	border: solid 2px var(--color-circular-inner-bg);
	opacity: 0;
	/* transition: border 0.66s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, width 0.66s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, height 0.66s cubic-bezier(0.645, 0.045, 0.355, 1) 0s; */
	transition: opacity 0.66s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, width 0.66s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, height 0.66s cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
		transform 0.66s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
	/* , top 0.01s ease-in-out 0s, left 0.01s ease-in-out 0s; */
}

.lupe.active {
	opacity: 1;
	border: solid 2px var(--color-circular-inner-bg);
}

header {
	position: fixed;
	top: 0;
	width: 100%;
	text-align: center;
	z-index: 1000;
	border-bottom: 3px solid transparent;
	/* background-color: var(--color-bg); */

	backdrop-filter: 0px;

	transition: backdrop-filter 0.66s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, background-color 0.66s cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
		border-bottom 0.66s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
	will-change: backdrop-filter, background-color;
}
header.scroll-down {
	background-color: #04080efe;
	border-bottom: 3px solid var(--color-border);
	background-color: var(--color-transparent-bg);
	backdrop-filter: var(--bg-blur);
}

@media (max-width: 999px) {
	/* header {
		display: none;
	} */

	#services {
		display: none;
	}
}

.header__inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 3rem;
	padding: 0 3rem;
	max-width: 1600px;
	margin: auto;
}

.logo {
	width: 100%;
	padding: 3rem 0;
	transition: all 0.33s ease-out;
	text-align: left;
}

@media (max-width: 900px) {
	.logo {
		width: 100%;
		padding: 1rem 0;
		transition: all 0.33s ease-out;
		text-align: left;
	}

	header logo {
		text-align: center;
	}
	header nav {
		display: none !important;
	}
}

header.scroll-down .logo {
	width: 100%;
	padding: 1.2rem 0;
}

header nav ul {
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 0;
	margin: 0;
}
header nav ul li {
	list-style: none;
	opacity: 1;
	padding: 2rem 3rem;
}
header nav ul li:last-child {
	padding: 0 0 0 3rem;
}

header nav ul li a {
	text-decoration: none;
	color: #fff;
	font-size: 1.2rem;
	width: 100%;
	padding: 0;
	letter-spacing: 1px;

	transition: opacity 0.33s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, letter-spacing 0.33s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, color 0.33s cubic-bezier(0.645, 0.045, 0.355, 1);
	will-change: opacity;
}

header nav ul:has(a:hover) li a {
	list-style: none;
	opacity: 0.66;
}

header:hover nav ul li:hover a:hover {
	list-style: none;
	opacity: 1;
	color: #37bbff;
}

@media (max-width: 900px) {
	.header__inner {
		padding: 0 !important;
		text-align: center !important;
		flex-grow: unset !important;
	}
	header nav ul li {
		padding: 1rem 1rem !important;
	}
	header nav ul li:last-child {
		padding: 1rem 1rem !important;
	}
}

.radial_blue {
	background: radial-gradient(circle, rgba(51, 143, 235, 0.1) 0%, rgba(51, 143, 235, 0) 50%);
}

button {
	outline: none;
	border: none;
	cursor: pointer;
}

.button-style-1 {
	border-radius: 999px;
}

.demo-2 {
	--color-link: #b95e5d;
}

.demo-3 {
	--color-link: #f7bcac;
}

.demo-4 {
	--color-link: #bf8b5b;
}

.lil-gui {
	visibility: hidden;
}

.demo-4 .lil-gui {
	visibility: visible;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: "";
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;
}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 1);
	}
}

a {
	text-decoration: none;
	color: var(--color-link);
	outline: none;
}

a:hover {
	color: var(--color-link-hover);
	outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
	/* Provide a fallback style for browsers
	 that don't support :focus-visible */
	outline: none;
	background: lightgrey;
}

a:focus:not(:focus-visible) {
	/* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
	background: transparent;
}

a:focus-visible {
	/* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
	outline: 2px solid red;
	background: transparent;
}

h2.letter-spacing-animation {
	letter-spacing: 10px;
	animation-name: letter-spacing-animation;
	animation-duration: 1.2s;
	animation-delay: 0.5s;
	opacity: 0;
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
}

@keyframes letter-spacing-animation {
	from {
		letter-spacing: 10px;
		opacity: 0;
	}
	to {
		letter-spacing: 0;
		opacity: 1;
	}
}

.unbutton {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	font: inherit;
}

.unbutton:focus {
	outline: none;
}

.frame {
	padding: 3rem 5vw;
	text-align: center;
	position: relative;
	z-index: 1000;
}

.frame__title {
	font-size: 1rem;
	margin: 0 0 1rem;
	font-weight: normal;
}

.frame__links {
	display: inline;
}

.frame__links a:not(:last-child),
.frame__demos a:not(:last-child) {
	margin-right: 1rem;
}

.frame__demos {
	margin: 1rem 0;
}

.frame__demo--current,
.frame__demo--current:hover {
	color: var(--color-text);
}

.content {
	width: 100%;
	position: relative;
	margin: auto;
	max-height: 100vh;
}

.mw-1600 {
	max-width: 1600px;
	padding: 0 3rem;
	margin: auto;
}

@media (max-width: 900px) {
	.mw-1600 {
		max-width: 900px !important;
		padding: 0 1rem !important;
	}
}

.md__mw-full {
	max-width: 100%;
	padding: 0 3rem;
	margin: auto;
}

.content__hero {
	/* font-family: mono45-headline, monospace; */
	max-width: 100%;
	margin: 0;
	text-align: center;
	height: 100vh;
	height: 100svh;
	padding-top: 8rem;
	padding-top: 20vh;
	padding-top: 20svh;
	/* display: flex; */

	transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

@media (max-width: 900px) {
	.content__hero {
		padding-top: 14vh !important;
	}
}

.content__hero.scroll {
	/* Translate X and Y */
	transform: translate3d(0, -100vh, 0);
	opacity: 0;
}

.content__hero .text-content {
	display: inline-block;
	box-shadow: 0px 0px 16px rgba(4, 14, 28, 0.5), 0px 0px 64px rgba(4, 14, 28, 0.5) 0px 0px 128px rgba(4, 14, 28, 0.5);
}

.content .content__title--main-heading {
	font-size: 5rem;
	line-height: 1;
	padding: 0;
	text-wrap: balance;
	color: var(--color-text);
	font-weight: 600;
	margin: 0;
	/* Font */
	font-family: "mono45-headline", monospace;
	font-family: "Open Sans", sans-serif;
	font-family: "Ubuntu", sans-serif;
	letter-spacing: -0.025em;

	/* Image Effect */
	will-change: transform;
	background-color: var(--color-text);
	/* background-image: url(../img/animated-heading-background_blue.png); */
	background-position: center;
	background-repeat: no-repeat;
	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text;
}
@media (max-height: 999px) {
	.content .content__title--main-heading {
		font-size: 3.4rem !important;
	}
}
@media (max-width: 999px) {
	.content .content__title--main-heading {
		font-size: 3rem !important;
		/* font-size: 2.2rem; */
	}

	.md__mw-full {
		max-width: 100%;
		margin: auto;
		padding: 0 1rem;
	}

	.md__block {
		display: block !important;
	}
	.md__grid {
		display: grid !important;
		grid-template-columns: 1fr;
	}
}
@media (max-width: 900px) {
	.content .content__title--main-heading {
		font-size: 3rem;
		font-size: 2.2rem;
	}
}

.content__hero .content__tagline {
	max-width: 1000px;
}

.content__cta {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem 0;
}

.content__cta button {
	/* background: #fff; */
	/* background-color: transparent; */
	background-color: rgba(4, 14, 28, 0.9);
	/* color: var(--color-bg); */
	color: #cbdfff;
	color: #fff;
	padding: 10px 32px;
	/* font-family: "mono45-headline"; */
	font-size: 1.2rem;
	border: solid 2px;
	/* border-radius: 99px; */
	letter-spacing: 1;

	transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}
.content__cta button:hover {
	color: #4a90ff;
	color: #37bbff;
	border-color: #cbdfff;
	border-color: #37bbff;
}

.content__section.section-1 {
	position: relative;

	opacity: 0;
	transform: translate3d(0, 0, 0);
	pointer-events: none;
	top: -50vh;

	transition: top 0.5s 0s ease-in-out, opacity 0.5s ease-in-out;
}
.content__section.section-1.active {
	opacity: 1;
	pointer-events: auto;
	/* transform: translate3d(0, -100vh, 0); */
	top: -100vh;

	transition: top 0.5s 0.1s ease-in-out, opacity 0.5s 0.3s ease-in-out;
}

.content__section .card {
	backdrop-filter: blur(6px);
	position: relative;

	--mouse-x: 0;
	--mouse-y: 0;

	/* TODO: Hier den magic glow Effect bei mouseover integieren (wie bei Projectreport in dark-mode beim ausfüllen eines neuen Projects) */
}

.content__section .card:last-child {
	margin-bottom: 0;
}

/* Card interactive border */
.content__section .card:before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	background-repeat: no-repeat;
	background-origin: padding-box;
	background-clip: border-box;

	background-color: rgb(126 180 255 / 50%);
	/* clip-path: polygon(3px 100%, 0 100%, 0 0, 100% 0, 100% 100%, 3px 100%, 3px calc(100% - 3px), calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) 3px, 3px 3px); */
	background-image: radial-gradient(circle, #cce0ff 0%, #040e1c00 33%);
	/* background-image: radial-gradient(circle, #ff0000 0%, #040e1c00 33%); */

	background-size: 200% 200%;
	background-repeat: no-repeat;

	/* New CSS Chrome Dev Tool CHanges  */
	/* clip-path: polygon(3px 100%, 0 100%, 0 0, 100% 0, 100% 100%, 3px 100%, 3px calc(100% - 3px), calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) 3px, 3px 3px); */
	/* background-image: radial-gradient(circle, rgb(191, 235, 252) 0%, #040e1c00 50%); */
	background-position: var(--mouse-x) var(--mouse-y);

	transition: background-color 0.3s cubic-bezier(0.15, 0.5, 0.66, 0.99);
	will-change: background-position, background-color;

	border-radius: 24px;
}
.content__section .card:hover:before {
	background-color: rgb(126 180 255 / 66%);
	background-size: 200% 200%;
	background-repeat: no-repeat;

	/* New CSS Chrome Dev Tool CHanges  */
	/* clip-path: polygon(3px 100%, 0 100%, 0 0, 100% 0, 100% 100%, 3px 100%, 3px calc(100% - 3px), calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) 3px, 3px 3px); */
	/* background-image: radial-gradient(circle, rgb(191, 235, 252) 0%, #040e1c00 50%); */
	/* background-position: var(--mouse-x) var(--mouse-y); */
}

/* .content__section .card:after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	top: -100%;
	background: rgba(4, 14, 28, 1);
	background: radial-gradient(circle, rgba(120, 176, 255, 1) 0%, rgba(4, 14, 28, 0) 150%);
} */

.content__section .card .inner-card {
	/* background-color: rgb(0 2 5 / 66%); */
	background-color: #04080efe;
	/* clip-path: polygon(3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px)); */
	position: relative;
	overflow: hidden;
	width: calc(100% - 4px);
	height: calc(100% - 4px);
	margin: 2px;
	border-radius: 22.5px;

	--mouse-x: 0;
	--mouse-y: 0;
}

.content__section .card .inner-card:before {
	content: "";
	position: absolute;
	width: 200%;
	height: 200%;

	background-position: 0;
	background-repeat: no-repeat;
	background-origin: padding-box;
	background-clip: border-box;

	left: var(--mouse-x);
	top: var(--mouse-y);

	pointer-events: none;

	opacity: 0.1;
	background-image: radial-gradient(circle, rgba(117, 175, 255, 0.1) 0%, rgb(4 14 28 / 0%) 300px);
	/* background-image: radial-gradient(circle, #ff0000 0%, rgb(4 14 28 / 0%) 50px); */

	transition: opacity 0.3s cubic-bezier(0.15, 0.5, 0.66, 0.99);
	will-change: opacity, top, left;
}

.content__section .card:hover .inner-card:before {
	content: "";
	background-color: #ffffff00;
	background-image: radial-gradient(circle, rgba(117, 175, 255, 0.1) 0%, rgb(4 14 28 / 0%) 300px);
	/* background-image: radial-gradient(circle, #ff0000 0%, rgb(4 14 28 / 0%) 50px); */
	pointer-events: none;
	opacity: 1;
}

.content__section .card .inner-card .card-content {
	padding: 1.8rem 2rem 2rem 2rem;
}

.content__section .card .inner-card .card-content .icon-wrapper {
	position: relative;
	width: 80px;
	max-width: 80px;
	height: 80px;
	max-height: 80px;
}

.content__section .card .inner-card .card-content .image-mask {
	width: 100%;
	height: 100%;
	/* border-radius: 100%; */
	background-color: rgb(126 180 255 / 50%);
	background-color: var(--color-text);
	background-color: rgba(var(--color-accent) / 80%);

	/* background-image: url(/img/svgs/infrastructure-chip-mask.svg); */
	/* -webkit-mask-image: url(/img/svgs/infrastructure-chip.svg);
	mask-image: url(/img/svgs/infrastructure-chip.svg); */
}

.content__section .card:hover .inner-card .card-content .image-mask {
	background-color: rgba(200 235 255 / 80%);
	background-image: radial-gradient(circle, rgba(180 220 255 / 100%) 0%, #040e1c00 100%);
	background-image: radial-gradient(circle, rgba(200 235 255 / 100%) 0%, #040e1c00 50%);
	/* background-image: radial-gradient(rgba(255, 0, 0 / 100%) 0%, #040e1c00 100%); */
	background-size: 400% 400%;
	background-repeat: no-repeat;

	background-position: var(--mouse-x) var(--mouse-y);
}

.content__section .card .inner-card .card-content p.content__text:last-child {
	padding-bottom: 0;
}

/***************************/
/* Utility Content-Classes */
/***************************/

.content__title--centered {
	text-align: center;
	justify-self: center;
}

.content__title--style-1 {
	font-family: new-order, sans-serif;
	font-weight: 400;
	font-size: 10vw;
	line-height: 0.85;
	text-wrap: balance;
	background: linear-gradient(90deg, #e9a680 0%, #992d46 50%, #064cb5 100%);
	background-size: cover;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}

.content__title--style-2 {
	font-family: bely-display, serif;
	font-weight: 400;
	font-size: 10vw;
	opacity: 0.7;
	line-height: 0.8;
}

.content__tagline {
	font-size: 1.2rem;
	line-height: 1.5;
	text-wrap: balance;
	color: #fff;
	margin: 0 auto;
	font-weight: 300;
	padding: 1rem 0;
	/* Font */
	font-family: "tt-autonomous", sans-serif;
	letter-spacing: 1;
}

.content__text {
	font-size: 1rem;
	line-height: 1.5;
	text-wrap: pretty;
	color: #fff;
	margin: 0 auto;
	font-weight: 300;
	padding: 1rem 0;
	/* Font */
	font-family: "tt-autonomous", sans-serif;
	letter-spacing: 1;
}

/* @media (min-width: 53em) {
	.frame {
		position: fixed;
		text-align: left;
		z-index: 100;
		top: 0;
		left: 0;
		display: grid;
		align-content: space-between;
		width: 100%;
		max-width: none;
		height: 100vh;
		padding: 1.5rem 3.35rem;
		pointer-events: none;
		grid-template-columns: auto 1fr;
		grid-template-rows: auto auto auto;
		grid-template-areas:
			"title ..."
			"... ..."
			"links demos";
	}
	.frame__title-wrap {
		grid-area: title;
		display: flex;
	}
	.frame__title {
		margin: 0;
	}
	.frame__tagline {
		position: relative;
		margin: 0 0 0 0.25rem;
		padding: 0 0 0 1rem;
		opacity: 0.5;
	}
	.frame__demos {
		margin: 0;
		grid-area: demos;
		justify-self: end;
	}
	.frame__links {
		grid-area: links;
		padding: 0;
		justify-self: start;
	}
	.frame a {
		pointer-events: auto;
	}
} */
#canvasContainer {
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	max-width: 100vw;
	height: 100%;
	max-height: 100vh;
	max-height: 100svh;
}
#canvasContainer img {
	visibility: hidden;
	pointer-events: none;
	position: absolute;
	max-width: 100vw;
	height: 100%;
	max-height: 100vh;
	max-height: 100svh;
}

/* SVG Stuff */
svg.svg-map {
	max-width: 100%;
}

/* Active States */
/* .active .active-fixed {
	position: sticky;
	top: 0;
} */

/* simple utilities */
.sticky {
	position: sticky;
	top: 0;
	height: auto;
}

.center {
	text-align: center;
}

.flex {
	display: flex;
	align-items: flex-start;
	gap: 3rem;
	flex-grow: 1;
	flex-basis: 0;
}

.flex > * {
	flex-grow: 1;
	flex-basis: 0;
	overflow: visible;
	/* display: flex; */
	/* align-items: flex-start; */
}

.pt-1 {
	padding-top: 3rem;
}
.pt-2 {
	padding-top: 3rem;
}
.pt-3 {
	padding-top: 3rem;
}
.pt-4 {
	padding-top: 4rem;
}
.pt-5 {
	padding-top: 5rem;
}
.pt-6 {
	padding-top: 6rem;
}

.pb-0 {
	padding-bottom: 0;
}

.vh100 {
	height: 100vh;
}

.mvh100 {
	min-height: 100vh;
}

.align-center {
	align-items: center;
}

.text-xl-6 {
	font-size: 6rem;
	margin: 0;
	padding: 0rem 0;
}
.text-xl-4 {
	font-size: 4rem;
	margin: 0;
	padding: 0rem 0;
}
.text-xl-2 {
	font-size: 2rem;
	margin: 0;
	padding: 0rem 0;
}

.font-weight-4 {
	font-weight: 400;
}
